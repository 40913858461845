<div class="header">
    <app-header></app-header>
    <div class="container">
        <div class="panel-align align-items-center">
<mat-card style="text-align: center; width: 40%; margin: 0 auto;" >
    <mat-card-title>ERROR!!</mat-card-title>
    <mat-card-subtitle>You are not authorized to use the application...</mat-card-subtitle>
    
</mat-card>
</div>
</div>
</div>
