<!-- <div mat-dialog-content>
    <strong mat-dialog-title>{{data.code}}</strong>
    <span class="content-span full-width">{{data.msg}}</span>
</div>
<br>
<div mat-dialog-actions style="display:block;text-align:center">
    <button mat-flat-button  [mat-dialog-close]="false">{{'general.no'|translate}}</button>
    <button mat-flat-button  [mat-dialog-close]="true" translate>{{'general.yes'|translate}}</button>
</div> -->




<div>
    <div class="content-container">
        <mat-icon id="close-icon" (click)="closeDialog()">close</mat-icon>
        <span class="content-span full-width">{{data.msg}}</span>
    </div>
    <button mat-flat-button id="no-button" [mat-dialog-close]="false">NO</button>
    <button mat-flat-button id="yes-button" [mat-dialog-close]="true">YES</button>
</div>