import { Injectable, Injector} from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpHeaders } from '@angular/common/http';
import { catchError, finalize, Observable, throwError } from 'rxjs';
import { SnackbarService } from 'src/app/shared/snackbar/snackbar.service';
import { TranslateService } from '@ngx-translate/core';
import { PingAuthenticationService } from '../ping-authentication.service';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';
import * as CryptoJS from 'crypto-js';
import { SigninModalComponent } from 'src/app/shared/signin-modal/signin-modal.component';
@Injectable()

export class AuthInterceptorService implements HttpInterceptor {

  constructor(private _snackbarService : SnackbarService,
    private pingAuth : PingAuthenticationService,
    public cookie: CookieService,
    private dialog: MatDialog,
    public router: Router, 
    private translate: TranslateService,) { }

    // setCookie(name : string, value : string, expires_in  : number =  7200) {
    //   let dateNow = new Date();
    //   dateNow.setSeconds(dateNow.getSeconds() + expires_in);
    //   this.cookie.set(name, value, dateNow , environment.cookiePath, '', true);
    // }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    var authRequest = this.addAuthenticationToken(req);

    return next.handle(authRequest)

    

    // return next.handle(req).pipe(
    //   catchError( err => {
    //     if(err.status === 400){
    //       this._snackbarService.OpenSnackBar('warning', this.translate.instant('general.400'))
    //     }else
    //     if(err.status === 401){
    //       this._snackbarService.OpenSnackBar('warning', this.translate.instant('general.401'))
    //     }else
    //     if(err.status === 404){
    //       this._snackbarService.OpenSnackBar('warning', this.translate.instant('general.404'))
    //     }else
    //     if(err.status === 403){
    //       this._snackbarService.OpenSnackBar('warning', this.translate.instant('general.404'))
    //     }else
    //     if(err.status === 500){
    //       this._snackbarService.OpenSnackBar('warning', this.translate.instant('general.500'))
    //     }
    //     const error = err || err.statusText;
        
    //     return throwError(error);
    //   }),
    //   finalize(
    //     () => {}
    //   )
    // )
  }

  private addAuthenticationToken(req:HttpRequest<any>):HttpRequest<any> {
    let app = CryptoJS.AES.encrypt('prm', 'BPost-TMM-AES').toString();
    let uId = this.cookie.get('userID');
    if(this.cookie.get('_tokenJwt')){
    if (!(req.url.includes("wiam-np")) && !(req.url.includes("login.bpost")) && !(req.url.includes("userTokenGeneration"))){
      let user = CryptoJS.AES.encrypt(uId, 'BPost-TMM-AES').toString();
      return req.clone({
        // headers: req.headers.set("x-api-key","2hpstnYFauODIyNhG3gv3kJtbLaWlD42Vr2bT7q2000")

        // headers: req.headers.set("x-api-key","gjW2BZQhiAac6kD0pNQU5UKasL1PrKa8kOQtN6Hi")
        
        headers: new HttpHeaders({
          'x-api-key': environment.tokenXAPIKey,
          'User' : user,
          'Authorization': this.cookie.get('_tokenJwt'),
          'Application': app
        })
        
      });
    }
    // else{
    //   return req;
    // }

    let t1 = this.cookie.get('_expiryTime1')
    let exptime = new Date(parseInt(t1));
    let currTime = new Date();
    if(exptime < currTime && !(req.url.includes("userTokenGeneration")) && !(req.url.includes("wiam-np")) && !(req.url.includes("login.bpost"))){
      this.reLogin();
      return req;
    }else{
      return req;
    }
  }else{
    
    // this.pingAuth.callPingLoginService();
    // this.setCookie('isUserTokenGeneration','true')
    // this.pingAuth.showPingLogin();

    if(!(req.url.includes("wiam-np")) && !(req.url.includes("login.bpost")) && !(req.url.includes("userTokenGeneration"))){
      this.pingAuth.callPingLoginService();
      // this.pingAuth.showPingLogin();
    }
    return req;
   
  }
   
  }

  reLogin() {
    if(this.dialog.openDialogs.length==0){
    const dialogRef = this.dialog.open(SigninModalComponent, {
      width: '500px',
      disableClose: true,
      closeOnNavigation: true
    });
    dialogRef.afterClosed().subscribe(
      result => {
        if (result == 'yes') {
          let currentpage = localStorage.getItem('currentPage');
          if (currentpage) {
            this.router.navigate([currentpage]);
          }
        }else if(result == 'no'){
          this.pingAuth.logout()
        }
       
      }
    )
    }
  }
}
