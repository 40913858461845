<div class="header">
    <app-header></app-header>
    <div class="container">
        <div class="panel-align align-items-center">
<mat-card style="text-align: center; width: 40%; margin: 0 auto;" >
    <mat-card-title>Logout</mat-card-title>
    <mat-card-subtitle>You have successfully logged out..</mat-card-subtitle>
    <button mat-raised-button mat-button-sm color="primary" (click)="login()">Login Back</button>
</mat-card>
</div>
</div>
</div>

