<!-- <mat-toolbar class="main-header mat-elevation-z8">
    <img alt="logo" src="/assets/images/Druck.jpg" width="100">

    <div class="headerMenu">
        <button [matMenuTriggerFor]="lang" class="ml-10" mat-stroked-button style="margin-right: 15%;">{{selectedLang}}
            <mat-icon>expand_more</mat-icon></button>
        <mat-menu #lang="matMenu" x-position="before">
            <button mat-menu-item (click)="useLanguage('EN')">EN</button>
            <button mat-menu-item (click)="useLanguage('FR')">FR</button>
            <button mat-menu-item (click)="useLanguage('NL')">NL</button>
        </mat-menu>
        <div class="logoff" *ngIf="isSignIn">{{user}}&nbsp;&nbsp;&nbsp;|</div><div class="logoff" (click)="LogOff()" *ngIf="isSignIn">LogOff</div>
    </div>

</mat-toolbar> -->


<nav class="navbar fixed-top header-column mb-3 ">
    <div class="container d-flex">
        <div class="me-auto">
            <div class="bpost-logo" tabindex="0">
                <img src="assets/images/bpost_logo.svg" alt="bpost logo" />
            </div>
        </div>
        <!-- <div class="bd-highlight align-self-center me-5 ">
            <button [matMenuTriggerFor]="currentMenu" class="ml-10" mat-stroked-button style="margin-right: 15%;border: 0px solid currentColor;">{{currentPage}}
                <mat-icon>expand_more</mat-icon></button>
             <mat-menu #currentMenu="matMenu" x-position="before">
                <button mat-menu-item (click)="changePage('Management Service')">Management Service</button>
                <button mat-menu-item (click)="changePage('User Management')">User Management</button>
                
            </mat-menu> 
        </div> -->
        <div class="bd-highlight align-self-center me-5 ">
            <button [matMenuTriggerFor]="lang" class="ml-10" [disabled]="true" mat-stroked-button style="margin-right: 15%;border: 0px solid currentColor;">{{selectedLang}}
                <mat-icon>expand_more</mat-icon></button>
            <mat-menu #lang="matMenu" x-position="before">
                <button mat-menu-item for="en" (click)="useLanguage('EN')">EN</button>
                <button mat-menu-item (click)="useLanguage('FR')">FR</button>
                <button mat-menu-item (click)="useLanguage('NL')">NL</button>
            </mat-menu>
        </div>

        <!-- <div class="bd-highlight align-self-center faq csrPointer" tabindex="0" (click)="goToLogout()">{{'language_header.logoff' | translate}}</div> -->
        <div class="bd-highlight align-self-center" *ngIf="isSignIn">{{userId}} - {{this.userRole}}&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;</div>

        <div class="bd-highlight align-self-center faq csrPointer me-5" (click)="LogOff()" *ngIf="isSignIn" translate>signOn.logout</div>

        <!-- <div class="bd-highlight align-self-center" *ngIf="isSignIn">{{userId}} - {{this.userRole}}&nbsp;&nbsp;|&nbsp;&nbsp;</div>

        <div class="bd-highlight align-self-center faq csrPointer me-5" (click)="LogOff()" *ngIf="isSignIn" translate>signOn.logOff</div> -->

    </div>
</nav>