import { Injectable } from "@angular/core";
import { CookieService } from 'ngx-cookie-service';

@Injectable()
export class AuthService{

    constructor(public cookie: CookieService){}

    // setUserData(data:any){
    //     this.CookieService.remove('User');
    //     this.CookieService.put('User', JSON.stringify(data))
    // }

    getUserData(){
        // var User = this.CookieService.get('User')
        // if(User)
        //     return JSON.parse(User)

        var userId = this.cookie.get('userID')
        return userId
    }

    // removeUserData(){
    //     this.cookie.removeAll();
    // }
}