// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,

  //PMS Endpoints
  // AuthenticationAPI: 'https://api-prm-np.awsprv.bpost.cloud/st2/',
  // AuthenticationBarcodeMaskAPI: 'https://api-prm-np.awsprv.bpost.cloud/st2/',
  // optionsAPI: 'https://api-prm-np.awsprv.bpost.cloud/st2/',
  // serviceCrApi: 'https://api-prm-np.awsprv.bpost.cloud/st2/',
  // opProdAPI: 'https://api-prm-np.awsprv.bpost.cloud/st2/',
  // operationalOptionsAPI:'https://api-prm-np.awsprv.bpost.cloud/st2/',
  AuthenticationAPI: 'https://api-prm-np.bpost.cloud/st2/',
  AuthenticationBarcodeMaskAPI: 'https://api-prm-np.bpost.cloud/st2/',
  optionsAPI: 'https://api-prm-np.bpost.cloud/st2/', //uncomment it later
  // optionsAPI: 'http://nlb-parcels-st2-euwe01-prm-int-2-0e5c13bb917b95ea.elb.eu-west-1.amazonaws.com:3040/',
  serviceCrApi: 'https://api-prm-np.bpost.cloud/st2/',
  opProdAPI: 'https://api-prm-np.bpost.cloud/st2/',
  operationalOptionsAPI:'https://api-prm-np.bpost.cloud/st2/',
  operationalRequestAPI:'https://api-prm-np.bpost.cloud/st2/',
  // userAPI: ' https://api-prm-np.awsprv.bpost.cloud/st2/',
  userAPI: 'https://api-prm-np.bpost.cloud/st2/',
  xApiKey : "gjW2BZQhiAac6kD0pNQU5UKasL1PrKa8kOQtN6Hi",
  "tokenXAPIKey" : "kl85n3jn4m5BHOVfFq0SP8RM1LibLibZc9qEpyb2",
  "tokenUrl":"https://api-prm-np.bpost.cloud/st2/pms-usermanagment/userTokenGeneration",
  // https://api-prm-np.bpost.cloud/st2/pms-usermanagment/userTokenGeneration
  //PING Endpoints
  pingAuthURL: "https://wiam-np.bpost.cloud/as/authorization.oauth2",
  "redirect_uri" : "https://prm-st2.bpost.cloud/portal/home",
   //redirect_uri : "http://localhost:4200/portal/home",
  pingTokenUrl: "https://wiam-np.bpost.cloud/as/token.oauth2",
  userInfoApi: "https://wiam-np.bpost.cloud/idp/userinfo.openid",
  logoutUrl: "https://wiam-np.bpost.cloud/as/revoke_token.oauth2",
  cookiePath: "/",
  pingLogoutUrl: "https://wiam-np.bpost.cloud/idp/startSLO.ping",


  // https://api-prm-np.awsprv.bpost.cloud/st2/pms/product-option-service/displayallnameandversion
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
