import { Injectable } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NotifierComponent } from 'src/app/shared/notifier/notifier.component';


@Injectable({
  providedIn: 'root'
})
export class SnackbarService {

  constructor(private _snackBar : MatSnackBar) { }

  // OpenSnackBar(type: string, message: string, time?: any, hPosition?: any){
  //   let snack = this._snackBar.open(message,'',{
  //     duration: time? time:type == 'error' ? 5000 : 3000,
  //     horizontalPosition : hPosition ? hPosition : 'right',
  //     verticalPosition : 'bottom',
  //     panelClass: [type == 'error' ? 'red-snackbar': 'green-snackbar']  
  //   })
  // }

  // dismiss(){
  //   this._snackBar.dismiss();
  // }

  OpenSnackBar(buttonText:any,displayMessage:string,type:any){
    this._snackBar.openFromComponent(NotifierComponent,{
     data:{
       message:displayMessage,
       buttonText: buttonText,
       type: type
     },
      duration : (type == 'success' ? 10000 : 15000),
      horizontalPosition:'center',
      verticalPosition:'bottom',
      panelClass: [type == 'success' ? 'green-snackbar' : 'red-snackbar'],
    })
  }
}
