import { HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { UserManagementService } from 'src/app/services/user-management.service';
import { environment } from 'src/environments/environment';
import { CookieService } from 'ngx-cookie-service';
import * as CryptoJS from 'crypto-js';

@Component({
  selector: 'app-signin-modal',
  templateUrl: './signin-modal.component.html',
  styleUrls: ['./signin-modal.component.scss']
})
export class SigninModalComponent implements OnInit {
  uId: any;
  loading: boolean = false;

  constructor(public dialogRef: MatDialogRef<SigninModalComponent>,
    private userManagement : UserManagementService,
    public cookie: CookieService,
   ) { }

  ngOnInit(): void {
    this.uId = this.cookie.get('userID');
    //user will logoff after 2min if not clicked on yes
    setTimeout(() => {
      this.close()
    }, 120000);
  }

  close(){
    this.dialogRef.close('no')
  }

  setCookie(name: string, value: string, expires_in: number = 7200) {
    let dateNow = new Date();
    dateNow.setSeconds(dateNow.getSeconds() + expires_in);
    this.cookie.set(name, value, dateNow, environment.cookiePath, '', true);
  }


  reLogin(){
    this.dialogRef.close('yes')
    let userid = CryptoJS.AES.encrypt(this.uId, 'BPost-TMM-AES').toString();
    let app = CryptoJS.AES.encrypt('pms', 'BPost-TMM-AES').toString();
    let params = {
      "user" : userid,
      "pwd" : "",
      "application" : app
    }
    this.userManagement.userTokenGeneration(params).subscribe(res => {
      if(res){
        this.setCookie('_tokenJwt', res.token);
        this.setCookie('_expiryTime1', res.expiration);
        this.dialogRef.close('yes')
      }
    })
  }

}
