import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { AuthService } from 'src/app/services/authorization/authentication.service';
import { PingAuthenticationService } from 'src/app/services/ping-authentication.service';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html'
})
export class HeaderComponent implements OnInit {
  selectedLang!: string;
  isSignIn: boolean = true;
  // user: any;

  userId:any;
  userRole:any;
  currentPage!: string;

  constructor(private translate: TranslateService,
     private router : Router,private _authService:AuthService,
     private pingAuth : PingAuthenticationService,public cookie: CookieService,
     private dialogRef: MatDialog) { }

  ngOnInit(): void {
    this.selectedLang = "EN"

    // if(this.router.url == "/portal/home"){
    //   this.isSignIn = false
    // }

    // this.user = this._authService.getUserData().username;

    this.selectedLang = "EN"
    this.currentPage = localStorage.getItem('view')||"Product Management"
    this.userId = this.cookie.get('userID');
    // this.userRole = sessionStorage.getItem('Role');
    this.userRole = sessionStorage.getItem('Role');
  }

  ngDoCheck()
  {
    this.isSignIn = this.cookie.get('accessToken') ? true : false;
    this.userId = this.cookie.get('userID');
    this.userRole = sessionStorage.getItem('Role');
  }

  useLanguage(language: string): void {
    this.translate.use(language.toLocaleLowerCase());
    this.selectedLang = language;
    this.translate.currentLang = this.selectedLang.toLocaleLowerCase()
  }

  

  LogOff(){
    this.isSignIn = false
    this.pingAuth.logout()
    this.isSignIn = false;
    this.dialogRef.closeAll();
  }

}
