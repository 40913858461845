<app-header></app-header>
<app-spinner></app-spinner>
<div class="container mb-4">

    <div class="panel-align align-items-center ">

        <div class="container  headerHold" style="z-index: 101;">
            <div class="row" style="overflow-x: auto">
                <div class="col-12 text-center">

                    <mat-button-toggle-group class="font" name="fontStyle" aria-label="Font Style" class="btnStyle" (change)="selectoption($event)" #panel [value]="selectedVal">
                        <mat-button-toggle style="border-left: solid 1px #f14755;" value="operationalRequest" routerLink="operationalRequest" translate for="opReq">{{'menu.operationalRequest'|translate}}</mat-button-toggle>
                        <!-- {{(((url==='/operationalRequest')||(url==='/'))?'menu.operationalRequest':(url==='/operationalProduct'?'menu.operationalProduct':'menu.operationalOption'))|translate}}</mat-button-toggle> -->

                        <mat-button-toggle value="barcodeMask" routerLink="barcodeMask" for="mask">{{'menu.barcodeMask'|translate}}</mat-button-toggle>

                        <mat-button-toggle for="product" [matMenuTriggerFor]="dd">{{'menu.product'|translate}}</mat-button-toggle>
                        <mat-menu #dd="matMenu" class="menu-item" [overlapTrigger]="false">
                            <button mat-menu-item value="product" (click)="onChange()" for="opOption">{{'menu.comProduct'|translate}}
               </button>
                            <button mat-menu-item value="operationalProduct" (click)="onChangeOp()" for="opProd">{{'menu.operationalProduct'|translate}}
               </button >              
              
            </mat-menu>

            <mat-button-toggle value="options"  [matMenuTriggerFor]="dd1" for="option">{{'menu.options'|translate}}</mat-button-toggle>
            <mat-menu #dd1="matMenu"  class="menu-item" [overlapTrigger]="false">
               <button mat-menu-item  value="options" (click)="onChangeOption()" >{{'menu.comOptions'|translate}}
               </button>
                            <button mat-menu-item value="operationalOption" (click)="onChangeOpOption()">{{'menu.operationalOption'|translate}}
               </button >            
              
            </mat-menu>
             <mat-button-toggle value="attributes" routerLink="attributes" for="attr">{{'menu.attributes'|translate}}</mat-button-toggle>
            <mat-button-toggle value="barcodeRange" routerLink="barcodeRange" for="range">{{'menu.barcodeRange'|translate}}</mat-button-toggle>
            <mat-button-toggle value="handlingInstructions" routerLink="handlingInstructions" for="handlingIns">{{'menu.handlingInstructions'|translate}}
            </mat-button-toggle>
            <mat-button-toggle value="serviceCompatibilityRules" routerLink="serviceCompatibilityRules" for="serviceCmptbl">{{'menu.serviceCompatibilityRules'|translate}}
            </mat-button-toggle>
            <mat-button-toggle  value="users" routerLink="users" style="width: 100%;"><span>User Management</span></mat-button-toggle>
         </mat-button-toggle-group>
         <!-- <mat-icon (click)="onPreviousSearchPosition()">chevron_right</mat-icon> -->

         
      
      </div>
   </div>
</div>


   <mat-card class="content-card mat-elevation-z3">
      <router-outlet></router-outlet>
  </mat-card>
      </div>
   </div>