import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import * as CryptoJS from 'crypto-js';
import { CookieService } from 'ngx-cookie-service';
import { UserManagementService } from './user-management.service';
@Injectable({
  providedIn: 'root'
})
export class PingAuthenticationService implements CanActivate  {
  code = '';
  codeChallange = '';
  state = '';
  windowOpen = false;
  redirect_uri: any;
  environment: any;
  accessToken:any;
  idToken:any;
  loginError=false;
  auth_code_val = '';
  state_val = '';
  constructor(
    public route: ActivatedRoute, 
    public router: Router,
    private httpClient: HttpClient,
    // private configService: ConfigService,
    public cookie: CookieService,
    private readonly http: HttpClient,
    private userManagement: UserManagementService
  ) { }

  async canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean>
  {
      this.login();    
      window.scrollTo(0, 0);    
      return true;
  }

  public async login(): Promise<void> {
                   
    // this.commonService.show();      
    if(this.cookie.get('accessToken')) {
        
      this.userProfile();
    }
    else {
      if (!sessionStorage.getItem('code_challenge')) {
        // console.log("check")
        this.showPingLogin();
      }
      else{
        // console.log("check1")
        this.callPingLoginService();
      }
    }   
  }

  showPingLogin() {
    
    // this.codeChallange = this.generateCodeChallengeAndState();
    // this.state = this.generateCodeChallengeAndState();

    this.codeChallange="IsQWjOVme6JUtuDRTEMw6S66VNaby5yweFb_P9DKFTM"
    this.state ="j-ct0r-pOc5hG7mAMnN9AGnLbyKXYPHl4_PkhZCIZ8I"

    let objdata = {
      "client_id": "tmminternal",
      "response_type": "code",
      "scope": "openid",
      'redirect_uri': environment.redirect_uri,
      "code_challenge": this.codeChallange,
      "state": this.state
    }

    sessionStorage.setItem('code_challenge', this.codeChallange);
    sessionStorage.setItem('state', this.state);
    var url = environment.pingAuthURL + '?' + this.serializeData(objdata);
    // console.log(url)
    window.location.replace(url)

  }

  generateCodeChallengeAndState() {
    let code_Verifier = '';
    code_Verifier = this.generateRandomString(128);
    const cc = this.base64URL(CryptoJS.SHA256(code_Verifier))

    return cc;

  }

  generateRandomString(length: any) {
    var text = '';
    var possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-._~';
    for (var i = 0; i < length; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
  }

  base64URL(string: any) {
    return string.toString(CryptoJS.enc.Base64).replace(/=/g, '').replace(/\+/g, '-').replace(/\//g, '_')
  }

  serializeData = function (obj: any) {
    var str = [];
    for (var p in obj)
      str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
    return str.join("&");
  }

  callPingLoginService():any {  
    let auth_code_val = this.getParameterByName("code", window.location.href);
    let state_val = this.getParameterByName("state", window.location.href);

    if(!state_val || state_val != sessionStorage.getItem('state')) {
      // console.log('error page');
      this.clearSession();
      this.showPingLogin();
      return false;
    }
    this.codeChallange = sessionStorage.getItem('code_challenge') || '';
   
    var obj = {
      'code': auth_code_val,
      'grant_type': 'authorization_code',
      'client_id': 'tmminternal',
      'code_verifier': this.codeChallange,
      'redirect_uri': environment.redirect_uri,
    }

    let options = {
      headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
    };

    this.http
      .post(environment.pingTokenUrl, this.serializeData(obj), options)
      .subscribe((response:any) => {
        response=JSON.stringify(response);
        response=JSON.parse(response);
        // console.log('postresponse**', response);
        this.accessToken = response['access_token'];
        this.idToken = response['id_token'];
        this.setCookie('accessToken', this.accessToken, response['expires_in']);
        this.setCookie('id_token', this.idToken);
        this.setCookie('isAuthenticated', '1');
        this.setCookie('isUserTokenGeneration','true')
        this.userProfile();
        
        // let currentpage = localStorage.getItem('currentPage');
        // if(currentpage) {
        //   this.router.navigate([currentpage]);
        // }
        // else {
        //   this.router.navigate(['portal/home']);
        // }
      },
      err => {
        // console.log('error', err);
      }
    );
  }

  setCookie(name : string, value : string, expires_in  : number =  7200) {
    let dateNow = new Date();
    dateNow.setSeconds(dateNow.getSeconds() + expires_in);
    this.cookie.set(name, value, dateNow , environment.cookiePath, '', true);
  }

  public getParameterByName(name: string, url: string) {
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }

  //  /* User Profile Request */
   public async userProfile(): Promise<any>{
      let options = {
        headers: new HttpHeaders().set('Authorization', 'Bearer ' +this.cookie.get('accessToken'))
      };
      let self = this;
      this.http
      .get(environment.userInfoApi, options)
      .subscribe((response:any) => {
        
        if(response['given_name']) {
          this.setCookie('firstName', response['given_name']);
        }
        else {
          this.setCookie('firstName', response['FirstName']);
        }

        if(response['family_name']) {
          this.setCookie('lastName', response['family_name']);
        }
        else {
          this.setCookie('lastName', response['LastName']);
        }
        
        if(!response['uid']) {
          this.setCookie('userID', response['sub']);
        }
        else {
          this.setCookie('userID', response['uid']);
        }

        //uncomment for token part
        if(this.cookie.get('isUserTokenGeneration') == 'true'){
          this.userTokenGeneration(response['sub'])
        }else if(this.cookie.get('isUserTokenGeneration') == 'false'){
          this.setRoleView(response['sub'])
        }

        // this.setRoleView(response['sub'])
        
        this.setCookie('sub', response['sub']);
        localStorage.setItem('currentPage', this.router.url);  
        // this.authentication();
      },function (err) {
        self.clearSession();
        let currentpage = localStorage.getItem('currentPage');
        if(currentpage) {
          self.router.navigate([currentpage]);
        }
        else {
          self.router.navigate(['portal/home']);
        }
        // console.log("errorresponse",err);
        // this function handles error
      });
  }

  //8nov
  userTokenGeneration(name:any){
    let options = {
      headers: new HttpHeaders({
        'x-api-key' : environment.tokenXAPIKey
      })
    };
    let userid = CryptoJS.AES.encrypt(name, 'BPost-TMM-AES').toString();
    let app = CryptoJS.AES.encrypt('prm', 'BPost-TMM-AES').toString();
    let params = {
      "user" : userid,
      "pwd" : "",
      "application" : app
    }

    this.http
        .post(environment.tokenUrl,params,options)
        .subscribe((response:any) => {
          this.setCookie('_tokenJwt', response.token);
          this.setCookie('_expiryTime1', response.expiration);
          this.setCookie('isUserTokenGeneration', 'false');
          if(this.cookie.get('_tokenJwt')){
          this.setRoleView(name);

          
           let currentpage = localStorage.getItem('currentPage')
          if (currentpage) {
            this.router.navigate(['portal/home'])
          }
          else
          {
            this.router.navigate(['portal/home'])
          }
        }
      },
      err => {
              this.router.navigate(['error'])
          })
         }

          
  //         this.router.navigate(['portal/home']);
  //       }
  //      },err => {
  //       this.router.navigate(['error'])
  //     })
  // }

  setRoleView(userName: any) {
    let role: any[] = []
    this.userManagement.getOneUser(userName).subscribe(res => {
      if (res) {
        // let resData = JSON.parse(res);
        // let userRole = resData.userRole;
        let userRole = res.userRole;
        // for (let i = 0; i < userRole.length; i++) {
        //   role[i] = userRole[i].roleName
        // }
        role.push(userRole[0].roleName)
        // console.log(role,"--role")
        if (role.length != 0) {
          if (role.some(element => element == 'ADMIN')) {
            sessionStorage.setItem('Role', 'ADMIN')
          }
          else if (role.some(element => element == 'BU')) {
            sessionStorage.setItem('Role', 'BU')
          }
          else if (role.some(element => element == 'READ_ONLY')) {
            sessionStorage.setItem('Role', 'READ_ONLY')
          }
          else if (role.some(element => element == 'ICT')) {
            sessionStorage.setItem('Role', 'ICT')
          }else if (role.some(element => element == 'BARCODE_RANGE_ADMIN')) {
            sessionStorage.setItem('Role', 'BARCODE_RANGE_ADMIN')
          }
          
        }
        else{
          sessionStorage.setItem('Role', 'READ_ONLY')
        }
      }
    },
      err => {
        // console.log(err,"get one user ")
        this.router.navigate(['error'])
      })
  }
  //

  // // authentication() {
  // //   let options = {
  // //     headers: new HttpHeaders({
  // //       'x-api-key' : this.environment.authorizationEndpointXapiKey
  // //     })
  // //   };
  // //   let self = this;
   
  // //    this.http
  // //     .get(this.environment.authorizationUrl+'/'+this.cookie.get('sub'),options)
  // //     .subscribe(response => {
  // //       this.setCookie('isAuthenticated', '1');
  // //       localStorage.setItem('isAuthenticated', '1');
  // //       this.setCookie('isAuthenticated', '1');
  // //       self.commonService.hide();
  // //    },function (err) {               
  // //       self.commonService.hide();   
  // //       self.router.navigate(['error']);
  // //    });
  // // }
  
  logout(){
    this.idToken = this.cookie.get('id_token') || '';
    var obj = {
      'client_id': 'tmminternal',
      'token' : this.idToken        
    }
    let options = {
      headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
    };
     this.http
      .post(environment.logoutUrl,this.serializeData(obj),options)
      .subscribe(response => {
      //  console.log("logoutResponse",response);
      this.pingLogout();
      this.clearSession();
      this.router.navigate(['logout']);
       //location.href = this.environment.redirect_uri;
     },function (err) {
      //  console.log("logoutErrorResponse",err);
     });
  }

  public clearSession(): void {
    sessionStorage.removeItem('code_challenge')
    sessionStorage.removeItem('state')
    sessionStorage.clear();
    localStorage.removeItem('userInfo');
    localStorage.removeItem('isAuthenticated');   
    this.cookie.deleteAll(environment.cookiePath);
    this.cookie.deleteAll('/');
  }

  pingLogout() {
    let pingLogoutWindow : any = window.open(environment.pingLogoutUrl,'_blank');
    setTimeout(() => {
      // console.log("first")
      pingLogoutWindow.close();
      // setTimeout(()=>{
      //   console.log("second")
      // this.clearSession();
      // this.router.navigate(['logout']);
      // },100)
      
      },1000);      
  }
}

