import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthLayoutComponent } from './core/auth-layout/auth-layout.component';
import { MenuComponent } from './core/menu/menu.component';
import { LogoutComponent } from './logout/logout.component';
import { PingAuthenticationService } from './services/ping-authentication.service';
import { ErrorPageComponent } from './shared/error-page/error-page.component';

const routes: Routes = [
  {
    path: '',
    redirectTo : 'portal/home',
    pathMatch : 'full' 
  },
  
  {
    path : 'portal' , children : [  
      {
        path: '',
        redirectTo : '/portal/home',
        pathMatch : 'full'
      },
    {
    path:'home',
    component : MenuComponent,
    canActivate : [PingAuthenticationService],
    // },
    children:[
      {
        path: 'handlingInstructions',
        loadChildren: () => import('./handling-instruction/handling-instruction.module').then(m => m.HandlingInstructionModule), canActivate: [PingAuthenticationService]
      },
      {
        path: 'operationalProduct',
        loadChildren: () => import('./operational-product/operational-product.module').then(m => m.OperationalProductModule), canActivate: [PingAuthenticationService]
      },
      {
        path: 'options',
        loadChildren: () => import('./options/options.module').then(m => m.OptionsModule), canActivate: [PingAuthenticationService]
      },
      {
        path: 'operationalRequest',
        loadChildren: () => import('./operational-request/operational-request.module').then(m => m.OperationalRequestModule), canActivate: [PingAuthenticationService]
      },
      {
        path: 'barcodeMask',
        loadChildren: () => import('./barcode-mask/barcode-mask.module').then(m => m.BarcodeMaskModule), canActivate: [PingAuthenticationService]
      },
      {
        path: 'barcodeRange',
        loadChildren: () => import('./barcode-range/barcode-range.module').then(m => m.BarcodeRangeModule), canActivate: [PingAuthenticationService]
      },
      {
        path:'optionIncompatibilities',
        loadChildren: () => import('./option-incompatibilities/option-incompatibilities.module').then(m => m.OptionIncompatibilitiesModule), canActivate: [PingAuthenticationService]

      },
      {
        path:'operationalOption',
        loadChildren: () => import('./operational-option/operational-option.module').then(m => m.OperationalOptionModule), canActivate: [PingAuthenticationService]
      },
      {
        path: 'attributes',
        loadChildren : () => import('./attributes/attributes.module').then(m => m.AttributesModule), canActivate: [PingAuthenticationService]
      },
      {
        path: 'product',
        loadChildren: () => import('./product/product.module').then(m => m.ProductModule), canActivate: [PingAuthenticationService]
      },
      {
        path: 'serviceCompatibilityRules',
        loadChildren: () => import('./service-compatibility-rules/service-compatibility-rules.module').then(m => m.ServiceCompatibilityRulesModule), canActivate: [PingAuthenticationService]
      },
      {
        path: 'users',
        loadChildren: () => import('./user-management/user-management.module').then(m => m.UserManagementModule), canActivate: [PingAuthenticationService]
      },
    ]
  }]
  },
  { path: 'error', component: ErrorPageComponent },
  { path: 'logout', component: LogoutComponent }
  // {
  //   path:'',
  //   component : AuthLayoutComponent,
  //   children :[
  //     // {
  //     //   // path: 'session',
  //     //   path: 'portal',
  //     //   loadChildren : () => import('./session/session.module').then(m => m.SessionModule)
  //     // }
  //     { path: 'logout', component: LogoutComponent }
  //   ]
  // }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }


