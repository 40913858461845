import { Component, OnInit } from '@angular/core';
import {PingAuthenticationService} from '../services/ping-authentication.service'

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {

  constructor(
    private auth : PingAuthenticationService,
  ) {
    localStorage.removeItem('currentPage');
    localStorage.removeItem('view')
   }

  ngOnInit(): void {
  }

  login()
  {
    this.auth.login()
  }

}
