import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserManagementService {

  constructor(private http: HttpClient) { }
  getRoles(): Observable<any>{
    return this.http
    .get<any>(environment.userAPI + 'pms-usermanagment/getUserRole')
    .pipe(catchError(this.handleErrorObservable))
  }
  getUser(): Observable<any>{
    return this.http
    .get<any>(environment.userAPI + 'pms-usermanagment/getAllUsers')
    .pipe(catchError(this.handleErrorObservable))
  }
  
  getOneUser(userName : any): Observable<any> {
  
    return this.http.get<any>(environment.userAPI + 'pms-usermanagment/getUser/' + userName)
      .pipe(catchError(this.handleErrorObservable))
  }
  editUser(data:any,id:number): Observable<any>{
    return this.http
        .put<any>(environment.userAPI + 'pms-usermanagment/updateUser',data, { responseType : 'text' as 'json'})
        .pipe(catchError(this.handleErrorObservable))
  }
  createUser(data:any): Observable<any>{
    return this.http
        .post<any>(environment.userAPI + 'pms-usermanagment/createUser',data, { responseType : 'text' as 'json'})
        .pipe(catchError(this.handleErrorObservable))
  }

  deleteUser(seeId:any): Observable<any>{
    return this.http
        .delete<any>(environment.userAPI + 'pms-usermanagment/deleteUser/'+seeId, { responseType : 'text' as 'json'})
        .pipe(catchError(this.handleErrorObservable))              
  }

  userTokenGeneration( body: any): Observable<any> {
    let options = {
        headers: new HttpHeaders({
          'x-api-key' : environment.xApiKey
        })
    };
    return this.http
      .post<any>(environment.tokenUrl,body,options)
      .pipe(catchError(this.handleErrorObservable))
  }

  private handleErrorObservable(error: any) {
    let errordata = JSON.parse(error.error);  
    // console.log(error)   
    return throwError(()=>errordata)

  }
}
