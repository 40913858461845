import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatIconModule } from '@angular/material/icon';
import { HeaderComponent } from './core/header/header.component';
import { MenuComponent } from './core/menu/menu.component';
import { MatCardModule } from '@angular/material/card';
import { MatChipsModule } from '@angular/material/chips';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { SpinnerComponent } from './shared/spinner/spinner.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatMenuModule } from '@angular/material/menu'
import { AuthLayoutComponent } from './core/auth-layout/auth-layout.component';
// import ngx-translate and the http loader
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { MatPaginatorIntlService } from './services/mat-paginator-intl.service';
import { CookieModule } from 'ngx-cookie';
import { AuthService } from './services/authorization/authentication.service';
import { ConfirmDialogComponent } from './shared/confirm-dialog/confirm-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { AuthInterceptorService } from './services/authorization/auth-interceptor.service';
import { NotifierComponent } from './shared/notifier/notifier.component';
import { LogoutComponent } from './logout/logout.component';
import { UserListComponent } from './user-management/user-list/user-list.component';
import { UserManagementComponent } from './user-management/user-management.component';
import { SigninModalComponent } from './shared/signin-modal/signin-modal.component';
import { AngularDualListBoxModule } from 'angular-dual-listbox';
import { ErrorPageComponent } from './shared/error-page/error-page.component';
// import { SigninModalComponent } from './shared/signin-modal/signin-modal.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    MenuComponent,
    SpinnerComponent,
    AuthLayoutComponent,
    ConfirmDialogComponent,
    SigninModalComponent,
    NotifierComponent,
    LogoutComponent,
    ErrorPageComponent
  ],
  imports: [
    BrowserModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    CookieModule.withOptions(),
    AppRoutingModule,
    BrowserAnimationsModule,
    MatButtonModule,
    MatToolbarModule,
    MatButtonToggleModule,
    MatIconModule,
    MatCardModule,
    MatChipsModule,
    HttpClientModule,
    MatProgressBarModule,
    MatSnackBarModule,
    MatMenuModule,
    MatDialogModule,
    AngularDualListBoxModule
  ],
  providers: [
    {
      provide: MatPaginatorIntl, deps: [TranslateService],
      useClass: MatPaginatorIntlService
    },
    AuthService,
    { provide : HTTP_INTERCEPTORS, useClass: AuthInterceptorService, multi: true},
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}