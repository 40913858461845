
<div class="menu-container auth-background">
    <div class="header">
       <app-header></app-header>
       <app-spinner></app-spinner>
    </div>
    <br><br>
    <div class="content">
       <div class="app-inner">
             <router-outlet></router-outlet>
       </div>
    </div>
    <!-- <div class="footer">
      <h2>footer</h2>
    </div> -->
 </div>
 