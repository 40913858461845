import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NavigationStart,Event as NavigationEvent} from '@angular/router';
import {CommonService} from '../../services/common.service'
import { MatMenuTrigger } from '@angular/material/menu';
import { ActivatedRoute, Router} from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Location } from '@angular/common';
@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html'
})
export class MenuComponent implements OnInit {
  selectedVal: string | undefined;
  currentMenu : any;
  isUserOnly : boolean = false
 url:any
  constructor(private router:Router,
    private route: ActivatedRoute,
    private translate: TranslateService,CommonService : CommonService) {
    
      this.router.events
      .subscribe(
        (event: NavigationEvent) => {
          if(event instanceof NavigationStart) {
            // console.log(event);
            // console.log((event.url).split('/'))
           
            this.selectedVal = (event.url).split('/')[3]
          }
        });
   }

  
  ngOnInit(): void {
    this.selectedVal = (this.router.url).split('/')[3];
    this.currentMenu = sessionStorage.getItem('currentPage') || "product management"
    this.url = this.router.url
    // console.log("u",(this.url==='/portal/home/operationalRequest')||(this.url==='/'))
    // console.log("u1",(((this.url==='/portal/home/operationalRequest')||(this.url==='/'))?'menu.operationalRequest':(this.url==='/portal/home/operationalProduct'?'/portal/home/menu.operationalProduct':'/portal/home/menu.operationalOption')))
 
  }

  @ViewChild('panel', { read: ElementRef }) public panel!: ElementRef<any>;

  // public onPreviousSearchPosition(): void {
  //   this.panel.nativeElement.scrollTo( {left : (this.panel.nativeElement.scrollLeft + 150), bahavior : 'smooth'})
  // }

  // public onNextSearchPosition(): void {
  //   this.panel.nativeElement.scrollTo( {left : (this.panel.nativeElement.scrollLeft - 150), bahavior : 'smooth'})
  // }

  selectoption(event:any)
  {
    // console.log(event)
  }
  @ViewChild(MatMenuTrigger) ddTrigger!: MatMenuTrigger;

  value!: string;
  selectedValue!: string;
  op:boolean=true
  opoption:boolean=true
  disableOr:boolean=true
  disableOp:boolean=true
  // reload(i:number)
  // {
  //   for(let j=0;j<i;j++){
  //   location.reload()
  //   break;
  //   }
  // }
  onChangeOption(){  
  this.op=false
  this.opoption=true 
  this.router.navigate(['portal/home/options'])
}
onChangeOpOption(){  
  this.op=true
  this.opoption=false 
  this.router.navigate(['portal/home/operationalOption'])
}

onChangeOp(){
  this.disableOp=false
  this.disableOr=true  
  this.router.navigate(['portal/home/operationalProduct'])  
  }
 
    onChange(){     
      this.disableOp=true
      this.disableOr=false
    this.router.navigate(['portal/home/product'])    
   
    }
  
}