import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { catchError, Observable, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  public subject = new BehaviorSubject<any>(false);

  constructor(private _httpClient:HttpClient) { }

get(url:string):Observable<any>{
  return this._httpClient.get(url);
}

  post(url:string,model:any):Observable<any>{
    const body =JSON.stringify(model);
    let httpHeader =new HttpHeaders()
    .set('Content-Type','application/json');
  
    let option={
      headers:httpHeader
    };
    return this._httpClient.post(url,body,option).pipe(catchError(this.handleErrorObservable))
   } 
   put(url:string,model:any):Observable<any>{
    const body =JSON.stringify(model);
    let httpHeader =new HttpHeaders()
    .set('Content-Type','application/json');
  
    let option={
      headers:httpHeader
    };
    return this._httpClient.put(url,body,option).pipe(catchError(this.handleErrorObservable))
   } 
   delete(url:string,id:number):Observable<any>{
     return this._httpClient.delete(url+id).pipe(catchError(this.handleErrorObservable))
   }

   show() {   
    this.subject.next(true);
  }

  hide() {
    this.subject.next(false);
  }

   private handleErrorObservable(error: Response | any){
    return throwError(() => new Error(error))
  }
}
